import React, { Component } from "react";
import { connect } from "react-redux";

import "bootstrap/dist/css/bootstrap.min.css";

import TopFrom from "./components/TopForm";
import HeaderForm from "./components/HeaderForm";
import CardsForm from "./components/CardsForm";
import ProductsForm from "./components/ProductsForm";
import ContactForm from "./components/ContactForm";
import Footer from "./components/Footer";

class App extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div>
        <ContactForm />
        <CardsForm />
        <Footer />
      </div>
    );
  }
}

export default connect()(App);
