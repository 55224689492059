import React, { Component } from "react";
import { connect } from "react-redux";
import "../Store";

import Container from "react-bootstrap/Container";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";

import styles from "./products.module.scss";

function render_source() {
  const rs = (
    <div>
      <Container fluid={true}>
        <Row>
          <Col className={styles.title_container}>Developed Products</Col>
        </Row>

        <Row>
          <Col className={styles.hubb_color}>
            <div className={styles.img_container_params}>
              <img
                className={styles.img_params}
                src={`${process.env.PUBLIC_URL}/imgs/hubb.png`}
                alt="hubb"
              />
            </div>
          </Col>
        </Row>

        <Row>
          <Col className={styles.hubb_footer}>
            Web Application in India.
            <br />
            Market research platform for India.
          </Col>
        </Row>

        <Row>
          <Col className={styles.quespon_color}>
            <div className={styles.img_container_params}>
              <img
                className={styles.img_params}
                src={`${process.env.PUBLIC_URL}/imgs/quespon.png`}
                alt="quespon"
              />
            </div>
          </Col>
        </Row>

        <Row>
          <Col className={styles.quespon_footer}>
            Mobile / Web Apllication in Indian.
            <br />
            Finding best restaurants and cafes for local customers.
          </Col>
        </Row>
      </Container>
    </div>
  );
  return rs;
}

class ProductsForm extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return render_source();
  }
}

export default connect()(ProductsForm);
