import { createStore } from 'redux';

const initData = {
    data: '',
};

export function sampleReducer(state = initData, action)
{
    console.log("Connect to store.js");
    return state;
}

export default createStore(sampleReducer);