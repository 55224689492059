import React, { Component } from "react";
import { connect } from "react-redux";
import "../Store";

import Container from "react-bootstrap/Container";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";

import styles from "./top.module.scss";

function render_source() {
  const rs = (
    <div>
      <Container fluid={true}>
        <Row>
          <Col lg className={styles.col}>
            <div>
              <p className={styles.title}>Work for</p>
              <div className={styles.detail}>
                <span>Full Stack Developer</span>
              </div>
            </div>
            <br />
            <br />
          </Col>
          <Col lg className={styles.col}>
            <div>
              <p className={styles.title}>Experiences</p>
              <span className={styles.title_detail}>Development</span>
              <br />
              <div className={styles.detail}>
                <span>Web/Mobile application</span>
                <br />
                <span>ML/AI, IoT</span>
                <br />
                <span>IT Infrastructure</span>
              </div>
              <br />
              <span className={styles.title_detail}>Consultant</span>
              <div className={styles.detail}>
                <span>Systems architect</span>
                <br />
                <span>Project Management</span>
                <br />
                <span>Global Localization</span>
              </div>
            </div>
            <br />
            <br />
          </Col>
          <Col lg className={styles.col}>
            <div>
              <p className={styles.title}>Business partners</p>
              <div className={styles.detail}>
                <a href="http://www.technoforte.co.in/" target="_blank">
                  Technoforte Software Pvt. Ltd
                </a>
                <br />
                <a href="http://www.aptech-education.com/" target="_blank">
                  Aptech
                </a>
                <br />
                <a href="https://hurrayedutech.com/" target="_blank">
                  HURRAY
                </a>
              </div>
            </div>
            <br />
            <br />
          </Col>
        </Row>
      </Container>
    </div>
  );
  return rs;
}

class TopForm extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return render_source();
  }
}

export default connect()(TopForm);
