import React, { Component } from "react";
import { connect } from "react-redux";
import "../Store";

import Container from "react-bootstrap/Container";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";

import styles from "./header.module.scss";

function render_source() {
  const rs = (
    <div>
      <Container fluid={true} className={styles.container_params}>
        <Row className={styles.row_params}>
          <div className={styles.img_container}>
            <img
              className={styles.img_params}
              src={`${process.env.PUBLIC_URL}/imgs/myself.jpg`}
              alt="Logo"
            />
          </div>
          <div className={styles.name_container}>
            <div className={styles.name_params}>Kenichi Usami</div>
          </div>
        </Row>
      </Container>
    </div>
  );
  return rs;
}

class HeaderForm extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return render_source();
  }
}

export default connect()(HeaderForm);
