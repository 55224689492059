import React, { Component } from "react";
import { connect } from "react-redux";
import "../Store";

import Card from "react-bootstrap/Card";
import CardDeck from "react-bootstrap/CardDeck";

import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";

import styles from "./cards.module.scss";

function render_source() {
  const rs = (
    <div>
      <Container fluid={true} className={styles.container_params}>
        <Row>
          <div className={styles.card_div_params}>
            <CardDeck>
              <Card>
                <Card.Img
                  variant="top"
                  src={`${process.env.PUBLIC_URL}/imgs/cards/dubai_1.jpg`}
                />
                <Card.Header>Dubai</Card.Header>
                <Card.Body>
                  <Card.Title>Burj Khalifa</Card.Title>
                  <Card.Text>
                    With my friends who work for his family's company as the
                    manager. We met each other for the first time in five years.
                    He invited me to Dubai as an IT consultant.
                  </Card.Text>
                </Card.Body>
              </Card>
              <Card>
                <Card.Img
                  variant="top"
                  src={`${process.env.PUBLIC_URL}/imgs/cards/dubai_2.jpg`}
                />
                <Card.Header>Dubai</Card.Header>
                <Card.Body>
                  <Card.Title>Friend's house</Card.Title>
                  <Card.Text>
                    My friend invited me his family party and played billiards.
                    his family treated me a dinner which is traditional dishes
                    of Dubai.
                  </Card.Text>
                </Card.Body>
              </Card>
              <Card>
                <Card.Img
                  variant="top"
                  src={`${process.env.PUBLIC_URL}/imgs/cards/dubai_3.jpg`}
                />
                <Card.Header>Abu Dhabi</Card.Header>
                <Card.Body>
                  <Card.Title>Sheikh Zayed Grand Mosque</Card.Title>
                  <Card.Text>
                    We visited there by my friend's car and experienced the
                    traditional culture on this trip.
                  </Card.Text>
                </Card.Body>
              </Card>
            </CardDeck>
          </div>
        </Row>

        <Row>
          <div className={styles.card_div_params}>
            <CardDeck>
              <Card>
                <Card.Img
                  variant="top"
                  src={`${process.env.PUBLIC_URL}/imgs/cards/china_1.jpg`}
                />
                <Card.Header>Guangzhou in China</Card.Header>
                <Card.Body>
                  <Card.Title>System Localization</Card.Title>
                  <Card.Text>
                    Meeting with Chinese clients about a joint Web system
                    development.
                  </Card.Text>
                </Card.Body>
              </Card>
              <Card>
                <Card.Img
                  variant="top"
                  src={`${process.env.PUBLIC_URL}/imgs/cards/india_1.jpg`}
                />
                <Card.Header>Bangalore in India</Card.Header>
                <Card.Body>
                  <Card.Title>Hubb project</Card.Title>
                  <Card.Text>
                    Here is team Hubb. We worked for the web application project
                    there. Finally We launched the service to India.
                  </Card.Text>
                </Card.Body>
              </Card>
              <Card>
                <Card.Img
                  variant="top"
                  src={`${process.env.PUBLIC_URL}/imgs/cards/india_2.jpg`}
                />
                <Card.Header>Bangalore in India</Card.Header>
                <Card.Body>
                  <Card.Title>Quespon project</Card.Title>
                  <Card.Text>
                    Quespon is for finding best restaurants and cafes in India.
                    It also offers discounts for them. I developed this system
                    with my team.
                  </Card.Text>
                </Card.Body>
              </Card>
            </CardDeck>
          </div>
        </Row>

        <Row>
          <div className={styles.card_div_params}>
            <CardDeck>
              <Card>
                <Card.Img
                  variant="top"
                  src={`${process.env.PUBLIC_URL}/imgs/cards/india_3.jpg`}
                />
                <Card.Header>Bangalore in India</Card.Header>
                <Card.Body>
                  <Card.Title>Quespon project</Card.Title>
                  <Card.Text>
                    Here is Indian local company for developing Quespon. I
                    joined this compnay as a representative of the project.
                  </Card.Text>
                </Card.Body>
              </Card>
              <Card>
                <Card.Img
                  variant="top"
                  src={`${process.env.PUBLIC_URL}/imgs/cards/india_4.jpg`}
                />
                <Card.Header>Bangalore in India</Card.Header>
                <Card.Body>
                  <Card.Title>Quespon project</Card.Title>
                  <Card.Text>
                    We are team Quespon. I am the project manager and the
                    developer for Android application. I also work several
                    fields such as developing web application.
                  </Card.Text>
                </Card.Body>
              </Card>
              <Card>
                <Card.Img
                  variant="top"
                  src={`${process.env.PUBLIC_URL}/imgs/cards/india_5.jpg`}
                />
                <Card.Header>Bangalore in India</Card.Header>
                <Card.Body>
                  <Card.Title>Deewari celebration</Card.Title>
                  <Card.Text>
                    All companies have Deewari celebration in their company. We
                    song traditional songs. This is one of very good memory for
                    my Indian life.
                  </Card.Text>
                </Card.Body>
              </Card>
            </CardDeck>
          </div>
        </Row>

        <Row>
          <div className={styles.card_div_params}>
            <CardDeck>
              <Card>
                <Card.Img
                  variant="top"
                  src={`${process.env.PUBLIC_URL}/imgs/cards/india_6.jpg`}
                />
                <Card.Header>Bangalore in India</Card.Header>
                <Card.Body>
                  <Card.Title>IT freelance</Card.Title>
                  <Card.Text>
                    They are an IT freelance in India. They can handle a lot of
                    programming languages and have good IT technologies.
                  </Card.Text>
                </Card.Body>
              </Card>
              <Card>
                <Card.Img
                  variant="top"
                  src={`${process.env.PUBLIC_URL}/imgs/cards/india_7.jpg`}
                />
                <Card.Header>Bangalore in India</Card.Header>
                <Card.Body>
                  <Card.Title>English study</Card.Title>
                  <Card.Text>
                    She is good English teacher of my college. He is my friend
                    from Cameroon. We learned and spent lot of time together.
                  </Card.Text>
                </Card.Body>
              </Card>
              <Card>
                <Card.Img
                  variant="top"
                  src={`${process.env.PUBLIC_URL}/imgs/cards/india_8.jpg`}
                />
                <Card.Header>Bangalore in India</Card.Header>
                <Card.Body>
                  <Card.Title>College</Card.Title>
                  <Card.Text>
                    Here is a College. It teaches IT technology and English. I
                    entered this college and graduated. It is very different
                    study style from my county Japan.
                  </Card.Text>
                </Card.Body>
              </Card>
            </CardDeck>
          </div>
        </Row>
      </Container>
    </div>
  );
  return rs;
}

class CardsForm extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return render_source();
  }
}

export default connect()(CardsForm);
