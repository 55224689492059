import React, { Component } from "react";
import { connect } from "react-redux";
import "../Store";

import Container from "react-bootstrap/Container";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";

import styles from "./contact.module.scss";

function render_source() {
  const rs = (
    <div>
      <Container fluid={true}>
        <Row className={styles.fotter_row_params}>
          <Col>
            <div className={styles.fotter_title_params}>
              Copyright ©2023 Kenichi Usami
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
  return rs;
}

class ContactForm extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return render_source();
  }
}

export default connect()(ContactForm);
