import React, { Component } from "react";
import { connect } from "react-redux";
import "../Store";

import Container from "react-bootstrap/Container";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";

import styles from "./contact.module.scss";

function render_source() {
  const rs = (
    <div>
      <Container fluid={true} className={styles.main_container}>
        <Row>
          <Col>
            <div className={styles.title_container}>Kenichi Usami</div>
            <div className={styles.title_img_container}>
              <img
                  className={styles.img_myself_icon_params}
                  src={`${process.env.PUBLIC_URL}/imgs/myself2.png`}
              />
            </div>
          </Col>
        </Row>

        <Row>
          <Col>
            <div className={styles.img_container_params}>
              <a
                  href="https://cats-india.com/introduction/"
                  target="_blank"
              >
                <img
                    className={styles.img_icon_params}
                    src={`${process.env.PUBLIC_URL}/imgs/blog.png`}
                    alt="facebook"
                />
              </a>
              <a
                href="https://www.facebook.com/profile.php?id=100005673397282"
                target="_blank"
              >
                <img
                  className={styles.img_icon_params}
                  src={`${process.env.PUBLIC_URL}/imgs/flogo_rgb_hex-brc-site-250.png`}
                  alt="facebook"
                />
              </a>
              <a
                href="https://www.linkedin.com/in/kenichi-usami/"
                target="_blank"
              >
                <img
                  className={styles.img_icon_params}
                  src={`${process.env.PUBLIC_URL}/imgs/In-2C-128px-TM.png`}
                  alt="linkedin"
                />
              </a>

              <a href="https://github.com/65usami" target="_blank">
                <img
                  className={styles.img_icon_params}
                  src={`${process.env.PUBLIC_URL}/imgs/GitHub-Mark-120px-plus.png`}
                  alt="github"
                />
              </a>

              <a href="https://qiita.com/Usamimi" target="_blank">
                <img
                  className={styles.img_icon_params}
                  src={`${process.env.PUBLIC_URL}/imgs/qiita-square.png`}
                  alt="qiita"
                />
              </a>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
  return rs;
}

class ContactForm extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return render_source();
  }
}

export default connect()(ContactForm);
